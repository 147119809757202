import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams, useParams, Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Input, Select, Tag, Pagination, Spin, Empty, Tooltip } from 'antd'
import { CollectionsAdd24Regular, CheckmarkCircle24Filled } from '@fluentui/react-icons'
import { debounce } from 'lodash'
import { getPlatformURL, profilesURL } from '@/helpers/env'
import FadeIn from '@/components/FadeIn'
import Blob from '@/components/Blob'
import Button from '@/components/Button'
import MemberAvatar from '@/components/MemberAvatar'
import PlaygroundInfo from '../PlaygroundInfo'
import { fetchPlaygroundsAnalytics, setUserSearchText, setPlaygroundAnalyticsModalOpen } from '@/store/accounts/actions'
import { Container } from './styles'

const PlaygroundsAnalyticsTable = () => {
  const dispatch = useDispatch()
  const { accountId } = useParams()

  let [searchParams, setSearchParams] = useSearchParams()
  const playgroundSearchParam = searchParams.get('project')
  const metricSearchParam = searchParams.get('metric')

  const { themeConfig, pageSize: defaultPageSize } = useSelector((state) => state.app)
  const { userProfile } = useSelector((state) => state.users)
  const {
    allUsers,
    currentAccount: account,
    playgroundsAnalytics,
    userSearchText,
    filteredTeam,
    selectedAnalyticsTab,
    isLoading: isAccountsLoading,
  } = useSelector((state) => state.accounts)

  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedPlayground, setSelectedPlayground] = useState()
  const [selectedMetric, setSelectedMetric] = useState()
  const [selectedStatus, setSelectedStatus] = useState('all')
  const [uniquePlaygrounds, setUniquePlaygrounds] = useState([])

  const metrics = [
    { value: 'created', label: 'Started at', type: 'date' },
    { value: 'last_modified', label: 'Last modified at', type: 'date' },
    { value: 'lab_running_time', label: 'Lab time (min)', type: 'duration' },
    { value: 'comments_count', label: 'Commented', type: 'check' },
    { value: 'versions_count', label: 'Status', type: 'status-tag' },
  ]

  const teamId = filteredTeam === 'all' ? undefined : filteredTeam === 'not-in-teams' ? 'None' : filteredTeam

  const getUserMetric = (metricName, metricRawValue) => {
    const metricType = metrics?.find((m) => m.value === metricName)?.type

    let metricValue
    let metricText
    let metricColors = []

    if (metricType === 'percentage') {
      metricValue = Math.round((metricRawValue || 0) * 100, 2)
      metricText = metricValue ? (
        <div className="metric-text">
          <span>{metricValue}%</span>
          {metricValue !== 100 && <CheckmarkCircle24Filled className="check-icon" />}
        </div>
      ) : (
        '–'
      )

      metricColors = [
        { minValue: 75, backgroundColor: themeConfig.colors.geekblue4 },
        { minValue: 50, backgroundColor: themeConfig.colors.geekblue3 },
        { minValue: 25, backgroundColor: themeConfig.colors.geekblue2 },
        { minValue: 1, backgroundColor: themeConfig.colors.geekblue1 },
        { minValue: 0, backgroundColor: themeConfig.colors.gray2 },
      ]
    }

    if (metricType === 'number') {
      metricValue = metricRawValue || '–'
      metricText = metricValue

      metricColors = [
        { minValue: 20, backgroundColor: themeConfig.colors.magenta4 },
        { minValue: 10, backgroundColor: themeConfig.colors.magenta3 },
        { minValue: 5, backgroundColor: themeConfig.colors.magenta2 },
        { minValue: 1, backgroundColor: themeConfig.colors.magenta1 },
        { minValue: 0, backgroundColor: themeConfig.colors.gray2 },
      ]
    }

    if (metricType === 'duration') {
      metricValue = parseInt(metricRawValue / 60)
      metricText = metricValue ? `${metricValue} min` : '–'

      metricColors = [
        { minValue: 45, backgroundColor: themeConfig.colors.purple4 },
        { minValue: 30, backgroundColor: themeConfig.colors.purple3 },
        { minValue: 15, backgroundColor: themeConfig.colors.purple2 },
        { minValue: 1, backgroundColor: themeConfig.colors.purple1 },
        { minValue: 0, backgroundColor: themeConfig.colors.gray2 },
      ]
    }

    if (metricType === 'date') {
      metricValue = metricRawValue ? dayjs().diff(dayjs(metricRawValue), 'day') : null
      metricText = metricRawValue ? dayjs(metricRawValue).from() : '–'

      metricColors = [
        { minValue: 31, backgroundColor: themeConfig.colors.cyan4 },
        { minValue: 21, backgroundColor: themeConfig.colors.cyan3 },
        { minValue: 14, backgroundColor: themeConfig.colors.cyan2 },
        { minValue: 7, backgroundColor: themeConfig.colors.cyan1 },
        { minValue: null, backgroundColor: themeConfig.colors.gray2 },
      ]
    }

    if (metricType === 'check') {
      metricValue = !!metricRawValue
      metricText = metricValue ? (
        <div className="metric-text">
          <CheckmarkCircle24Filled className="check-icon success" />
        </div>
      ) : (
        '—'
      )
    }

    if (metricType === 'status-tag') {
      metricValue = metricRawValue
      let tagStatus
      let tagText

      if (!metricRawValue) {
        tagText = 'Not started'
      }

      if (metricRawValue === 0) {
        tagStatus = 'not-submitted'
        tagText = 'Not submitted'
      }

      if (metricRawValue > 0) {
        tagStatus = 'submitted'
        tagText = 'Submitted'
      }

      metricText = (
        <div className="metric-text">
          <Tooltip title={tagText}>
            <span>
              <Blob color={tagStatus === 'submitted' ? 'blue' : tagStatus === 'not-submitted' ? 'yellow' : null} />
            </span>
          </Tooltip>
        </div>
      )
    }

    const metricStyles = metricColors.find((c) => metricValue >= c.minValue)
    const hasValue = !!metricRawValue

    return [hasValue, metricText, metricStyles]
  }

  const renderMetric = (
    userAnalyticsData,
    metricName = selectedMetric,
    playgroundId = selectedPlayground?.id,
    playgroundCopyId,
  ) => {
    const userId = userAnalyticsData?.user_id
    const playgroundAnalytics = userAnalyticsData?.playgrounds?.find((data) => data?.id === playgroundId)
    const auxPlaygroundCopyId = playgroundCopyId || playgroundAnalytics?.playground_copy_id
    const metricRawValue = playgroundAnalytics?.[metricName]

    const [hasValue, metricText, metricStyles] = getUserMetric(metricName, metricRawValue)
    const keyValue = `${userId}-${playgroundId}-${metricName}`

    return (
      <td
        key={keyValue}
        className="stat-block has-content"
        style={{ ...metricStyles }}
        onClick={() =>
          dispatch(setPlaygroundAnalyticsModalOpen({ userId, playgroundId, playgroundCopyId: auxPlaygroundCopyId }))
        }
      >
        {metricText}
      </td>
    )
  }

  const handleSearchPlaygroundAnalaytics = (newPage, newPageSize, newSearch) => {
    setCurrentPage(newPage || 1)

    if (newPageSize) {
      setPageSize(newPageSize)
    }

    setUniquePlaygrounds(null)

    dispatch(
      fetchPlaygroundsAnalytics(account?.id, {
        search: userSearchText || newSearch,
        team_id: teamId,
        status: selectedStatus === 'all' ? null : selectedStatus,
        page_size: newPageSize || pageSize,
        page: newPage || 1,
      }),
    )
  }

  const handleTableChange = (page, pageSize) => {
    handleSearchPlaygroundAnalaytics(page, pageSize)
  }

  const handleUserSearch = useMemo(
    () =>
      debounce((evt) => {
        const search = evt?.target?.value?.toLowerCase()

        handleSearchPlaygroundAnalaytics(null, null, search)
      }, 300),
    [filteredTeam],
  )

  const handleGoBackToAllPlaygrounds = () => {
    setSelectedPlayground(null)

    if (playgroundSearchParam) {
      searchParams.delete('project')
      setSearchParams(searchParams, { replace: true })
    }
  }

  useEffect(() => {
    if (!playgroundsAnalytics) return

    const allPlaygrounds = playgroundsAnalytics?.results?.map((item) => item?.playgrounds)?.flat()

    let uniquePlaygrounds = allPlaygrounds?.map((item) =>
      JSON.stringify({
        id: item?.id,
        name: item?.name,
        author: item?.author,
        data_sources: item?.data_sources,
      }),
    )
    uniquePlaygrounds = [...new Set(uniquePlaygrounds)]?.map((s) => JSON.parse(s))
    setUniquePlaygrounds(uniquePlaygrounds)

    // first load
    if (!selectedPlayground && playgroundSearchParam) {
      setSelectedPlayground(allPlaygrounds?.find((p) => p?.id === playgroundSearchParam))
      return
    }
  }, [playgroundsAnalytics])

  useEffect(() => {
    if (!account) return

    if (selectedAnalyticsTab !== 'customProjects') {
      handleGoBackToAllPlaygrounds()
      return
    }

    handleSearchPlaygroundAnalaytics()
  }, [selectedAnalyticsTab, account, selectedStatus, filteredTeam]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedPlayground && playgroundSearchParam !== selectedPlayground) {
      setSearchParams(
        { ...Object.fromEntries(searchParams.entries()), project: selectedPlayground?.id },
        { replace: true },
      )
    }
  }, [selectedPlayground])

  useEffect(() => {
    if (!selectedMetric) {
      const isMetricValid = metrics?.find((m) => m?.value === metricSearchParam)
      setSelectedMetric(isMetricValid ? metricSearchParam : metrics?.[0]?.value)
    }

    if (selectedMetric && metricSearchParam !== selectedMetric) {
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), metric: selectedMetric }, { replace: true })
    }
  }, [selectedMetric])

  useEffect(() => {
    return () => {
      setSelectedPlayground(null)
    }
  }, [])

  return (
    <Container className="playgrounds-analytics-table-container" $isPlaygroundSelected={selectedPlayground}>
      {!isAccountsLoading && !!allUsers?.length && (
        <div className={`table-header ${selectedPlayground && 'is-selected'}`}>
          {selectedPlayground && (
            <div className="selected-playground-content">
              <PlaygroundInfo playground={selectedPlayground} handleBack={handleGoBackToAllPlaygrounds} />
            </div>
          )}
          <span />

          <div className="filters">
            <Input.Search
              className="user-search"
              value={userSearchText}
              onChange={(evt) => {
                dispatch(setUserSearchText(evt?.target?.value?.toLowerCase()))
                handleUserSearch(evt)
              }}
              allowClear
              placeholder="Search member..."
              loading={isAccountsLoading}
            />

            {selectedPlayground ? (
              <div className="actions">
                {/* <Button
                icon={<PersonArrowRight24Regular style={{ width: 16 }} />}
                onClick={() => dispatch(setAssignModalOpen(selectedPlayground))}
              >
                Assign
              </Button> */}

                <Link to={`${getPlatformURL(account?.slug)}custom-projects/${selectedPlayground?.id}`} target="_blank">
                  <Button type="primary">Go to project</Button>
                </Link>
              </div>
            ) : (
              <div className="selectors-container">
                <Select
                  className="metric-select status"
                  value={selectedStatus}
                  onChange={setSelectedStatus}
                  options={[
                    { value: 'all', label: 'All' },
                    { value: 'published', label: 'Published' },
                    { value: 'archived', label: 'Archived' },
                  ]}
                  virtual={false}
                  placeholder="Filter by Status"
                  loading={isAccountsLoading}
                  disabled={isAccountsLoading}
                />

                <Select
                  className="metric-select metric"
                  value={selectedMetric}
                  onChange={setSelectedMetric}
                  options={metrics}
                  virtual={false}
                  placeholder="Metric"
                  loading={!playgroundsAnalytics || isAccountsLoading}
                  disabled={!playgroundsAnalytics || isAccountsLoading}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {isAccountsLoading || uniquePlaygrounds === null ? (
        <div className="loading-container">
          <Spin size="large" />
          <p>Loading project analytics...</p>
        </div>
      ) : allUsers?.length ? (
        <div className="table-container">
          <div className={`overlap-top-left ${selectedPlayground ? 'sm' : ''}`} />

          <FadeIn>
            {playgroundsAnalytics?.count ? (
              <>
                <table className="table table-header-rotated">
                  <thead>
                    <tr>
                      <th />

                      {selectedPlayground ? (
                        metrics?.map((m) => (
                          <th key={m?.value} className="normal">
                            <div className="head-item-container">
                              <div className="head-item-content">{m?.label}</div>
                            </div>
                          </th>
                        ))
                      ) : uniquePlaygrounds?.length ? (
                        uniquePlaygrounds?.map((p) => (
                          <th key={p?.id} className="rotated">
                            <div className="head-item-container">
                              <div className="head-item-content" onClick={() => setSelectedPlayground(p)}>
                                {p?.name}
                              </div>
                            </div>
                          </th>
                        ))
                      ) : (
                        <th className="rotated empty">
                          <div className="head-item-container">
                            <div className="head-item-content">No available projects</div>
                          </div>
                        </th>
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {playgroundsAnalytics?.results?.map((userAnalyticsData) => {
                      const userData = allUsers?.find((userData) => userData?.user?.id === userAnalyticsData?.user_id)

                      return (
                        <tr key={userAnalyticsData?.user_id}>
                          <th className="row-header">
                            <MemberAvatar
                              lastName={userData?.user?.last_name}
                              firstName={userData?.user?.first_name}
                              avatarUrl={userData?.user?.avatar_url}
                              showName
                              isOwner={userData?.role === 'owner'}
                              externalId={userData?.user?.external_id}
                            />
                          </th>

                          {selectedPlayground ? (
                            metrics?.map((metric) => renderMetric(userAnalyticsData, metric?.value))
                          ) : uniquePlaygrounds?.length ? (
                            uniquePlaygrounds?.map((p) => {
                              const userPlayground = userAnalyticsData?.playgrounds?.find((data) => data?.id === p?.id)

                              return renderMetric(
                                userAnalyticsData,
                                undefined,
                                userPlayground?.id || p?.id,
                                userPlayground?.playground_copy_id,
                              )
                            })
                          ) : (
                            <td className="stat-block"></td>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>

                <div className="pagination-container">
                  <Pagination
                    current={currentPage}
                    onChange={handleTableChange}
                    total={playgroundsAnalytics?.count}
                    pageSize={pageSize}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} members`}
                  />
                </div>
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No members found" />
            )}
          </FadeIn>
        </div>
      ) : (
        <div className="empty-state-container">
          <div className="empty-state-content">
            <div className="header">
              <div className="container">
                <div className="title-container">
                  <CollectionsAdd24Regular className="icon" />

                  <h4 className="title">Custom projects</h4>
                </div>

                <p className="text">Create custom projects and track your users' progress.</p>

                <Link className="link" to={`/accounts/${accountId}/content?tab=customProjects`}>
                  <Button type="secondary">Go to Custom projects</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}

export default PlaygroundsAnalyticsTable
